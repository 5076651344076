
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "claim-settlement-offer-decline-modal-component",
  components: { ErrorMessage, Field, Form },
  props: {
    publicId: String,
    claimReference: String,
  },
  setup(props, { emit }) {
    const submitButton1 = ref<null | HTMLButtonElement>(null);
    const reason = ref("");

    const claimNumberValidator = Yup.object().shape({
      reason: Yup.string()
        .required()
        .label("Reason"),
    });

    const addCommaToNumberString = (amount: any) => {
      return variables.addCommaToNumberString(amount);
    };

    const submitForm = (_, { resetForm }) => {
      if (submitButton1.value) {
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`/settlement/${props?.publicId}/respond`, {
              additionalComments: reason.value,
              isAccepted: "false",
            })
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Successfully assigned claim number",
                  "success"
                );
                resetForm();
                hideModal(
                  document.getElementById(
                    "claim_settlement_offer_decline_modal"
                  )
                );
                emit("getSettlmentOffers");
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      claimNumberValidator,
      addCommaToNumberString,
      submitForm,
      submitButton1,
      reason,
    };
  },
});
